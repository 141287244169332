.megamenu {
  .bootstrap-select {
    .dropdown-menu {
      width: 100%;
      overflow: overlay !important;

      .bs-searchbox {
        position: sticky;
        top: 0;
        z-index: 9999;
        background: white;
      }

      > .inner {
        overflow: scroll !important;
      }
    }
  }

  .module_mod_iwp_filters {
    .list-inline {
      &.buttons {
        .list-inline-item {
          display: grid;
          gap: 0.5rem;
          margin-right: 0;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}